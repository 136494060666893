import request from "../util/SuperagentUtils";
import { formatDateMMDDyyyy } from "../util/DateUtils";

const BASE_URL = "/api/space-usage/supplier";

const getSpaceUtilizationQueryParams = (searchParams: {}, isPageable: boolean) => {

  const { page, size, startDate, endDate, locationId, locationName, bookingNumber, overageInvoiceNumber, sortBy, sortDir, groupBy } = searchParams;

  const queryParams = isPageable ? new URLSearchParams([["page", page || 0], ["size", size || 10]]) : new URLSearchParams();

  if (startDate) queryParams.append("startDate", formatDateMMDDyyyy(startDate));
  if (endDate) queryParams.append("endDate", formatDateMMDDyyyy(endDate));
  if (locationId) queryParams.append("locationId", locationId);
  if (locationName) queryParams.append("locationName", locationName);
  if (bookingNumber) queryParams.append("bookingNumber", bookingNumber);
  if (overageInvoiceNumber) queryParams.append("overageInvoiceNumber", overageInvoiceNumber);
  if (sortBy) queryParams.append("sortBy", sortBy);
  if (sortDir) queryParams.append("sortDir", sortDir);
  if (groupBy) queryParams.append("groupBy", groupBy);

  return queryParams;
}

export const getSupplierSpaceUsageByPage:
  (accountId: string, searchParams: {}) =>
    Promise = (accountId, searchParams) => {

  return request.get(`${BASE_URL}/${accountId}?${getSpaceUtilizationQueryParams(searchParams, true).toString()}`);
};

export const getSupplierSpaceUsageReportUrl = (accountId: string, searchParams) => {
  return `${BASE_URL}/${accountId}/report?${getSpaceUtilizationQueryParams(searchParams, false).toString()}`;
};

export const getSupplierSpaceUsageGroupSummaryReportUrl: (searchParams: {accountId: string; startDate: string; endDate: string; locationId: string; locationName: string, groupBy?: string}) =>
  string = (searchParams) => {
    let summaryParams;

    if(searchParams) {
        summaryParams= {
        startDate: searchParams.startDate,
        endDate: searchParams.endDate,
        locationId: searchParams.locationId,
        locationName: searchParams.locationName,
        groupBy: searchParams.groupBy
      }
    }

  return searchParams ? `/api/space-usage/supplier/${searchParams.accountId}/group-summary-report?${getSpaceUtilizationQueryParams(summaryParams, false)}` : "";

};