import React, { useRef, useCallback, useMemo } from "react";
import { Grid } from "@mui/material";
import { SortFilter } from '@securspace/securspace-ui-kit';
import { groupOption } from "./data";
import { getAllLocationsByBuyer } from "../location/requests/location-requests";
import useGetRequestBody from "../../hooks/useGetRequestBody";
import {withSnackbar} from "../hocs/withSnackbar";

const BuyerInventoryFilter = React.memo(({
  buyerAccountId,
  setSortBy,
  setSelectedLocation,
  setEquipmentType,
  setBookingNumber,
  setSortDir,
  setEquipmentNumber,
  bookingNumber,
  equipmentNumber,
  snackbarShowMessage,
  filterSelectOptions
}) => {

  const buyerLocations = useGetRequestBody(getAllLocationsByBuyer, [buyerAccountId], snackbarShowMessage);

  const locations = useMemo(() => {
    return [{id: "", locationName: "All"}, ...buyerLocations];
  },[buyerLocations]);

  const initialRender = useRef(true);

  const handleCallback = useCallback((e) => {
    const parseFilters = (filterArray) => {
      const bookingNumber = filterArray.find(item => item.name === "bookingNumber")?.value
      const equipmentNumber = filterArray.find(item => item.name === "equipmentNumber")?.value

      return {
        bookingNumber,
        equipmentNumber
      }
    }

    if (!initialRender.current) {
      setSortBy(e.sortBy);
      const parsedFilters = parseFilters(e.filters);
      setBookingNumber(parsedFilters.bookingNumber);
      setEquipmentNumber(parsedFilters.equipmentNumber);
      setEquipmentType(e.selectFilters.find(item => item.name === "equipmentType")?.value);
      setSelectedLocation(e.location);
      setSortDir(e.sortDir);
    } else {
      initialRender.current = false;
    }
  }, []);

  const filterByOptions = useMemo(() => {
    return [
      { label: "Booking Number", name: "bookingNumber", value: bookingNumber, numberField: true },
      { label: "Equipment Number", name: "equipmentNumber", value: equipmentNumber },
    ];
  }, [bookingNumber, equipmentNumber]);

  return (
    <Grid item>
      <SortFilter
        filterCallback={handleCallback}
        locations={locations}
        filterByOption={filterByOptions}
        sortByOption={groupOption}
        filterBySelectOption={filterSelectOptions}
        defaultSortBy={"checkInDate"}
        sortDir={"desc"}
      />
    </Grid>
  )
})

export default withSnackbar(BuyerInventoryFilter);
