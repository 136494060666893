import moment from "moment";
import request from "../../../../util/SuperagentUtils";
import { DateFormats } from "../../../constants/securspace-constants";

const BASE_URL = "/api/invoices/buyers";

const getBuyerInvoiceQueryParams = (searchParams: {}, isPageable: boolean) => {

  const { page, size, startDate, endDate, locationId, bookingNumber, invoiceNumber, transactionType, status, sortBy, sortDir } = searchParams;

  const queryParams = isPageable ? new URLSearchParams([["page", page || 0], ["size", size || 10]]) : new URLSearchParams();

  if (startDate) queryParams.append("startDate", moment(startDate).format(DateFormats.DAY));
  if (endDate) queryParams.append("endDate", moment(endDate).format(DateFormats.DAY));
  if (locationId) queryParams.append("locationId", locationId);
  if (bookingNumber) queryParams.append("bookingNumber", bookingNumber);
  if (invoiceNumber) queryParams.append("invoiceNumber", invoiceNumber);
  if (transactionType) queryParams.append("transactionType", transactionType);
  if (status) queryParams.append("status", status);
  if (sortBy) queryParams.append("sortBy", sortBy);
  if (sortDir) queryParams.append("sortDir", sortDir);

  return queryParams;
}

const getBuyerInvoices = (
    searchParams,
    onSuccess,
    onFail
) => {

  let params = getBuyerInvoiceQueryParams(searchParams, true);

  const result = request.get(`${BASE_URL}?${params}`);

  if (onSuccess && onFail) {
    result
      .then(onSuccess)
      .catch(onFail)
  } else {
    return result
  }
}

/**
 * Get the url for the buyer invoices report
 * @param buyerAccountId
 * @param searchParams
 * @returns {string}
 */
const getBuyerInvoicesReportUrl: (
  buyerAccountId: string,
  searchParams: {
    startDate?: string,
    endDate?: string,
    locationId?: string,
    bookingNumber?: string,
    invoiceNumber?: string,
    transactionType?: string,
    status?: string,
    sortBy?: string,
    sortDir?: string
}) => string = (buyerAccountId, searchParams) => {
  let params = getBuyerInvoiceQueryParams(searchParams, true);

  return `${BASE_URL}/report?${params.toString()}`;
};

const requestBuyerInvoicesSummary = (searchParams, onSuccess, onFail) => {

  let params = getBuyerInvoiceQueryParams(searchParams, true);

  const result = request.get(`${BASE_URL}/summary?${params}`);

  if (onSuccess && onFail) {
    result
      .then(onSuccess)
      .catch(onFail)
  } else {
    return result
  }
}

export {
    getBuyerInvoices,
    getBuyerInvoicesReportUrl,
    requestBuyerInvoicesSummary
}