import React, {useRef, useCallback, useMemo} from "react";
import { Grid } from '@mui/material'
import { SortFilter } from '@securspace/securspace-ui-kit';
import { filterBySelectOption, sortByFields } from './data';
import {withSnackbar} from "../hocs/withSnackbar";
import useUrlQueryParams from "../../hooks/useUrlQueryParams";
import useAllLocationNames from "../../hooks/components/location/useAllLocationNames";

const SupplierBookingFilter = ({
  setQuery,
  snackbarShowMessage
}) => {

  const locations = useAllLocationNames(snackbarShowMessage);
  const { bookingNumber } = useUrlQueryParams();
  const initialRender = useRef(true);

  const handleCallback = useCallback((e) =>{
    if (!initialRender.current) {
      setQuery((prevState) => ({
        ...prevState,
        sortBy: e.sortBy,
        selectedLocation: e.location,
        startDate: e.startDate,
        endDate: e.endDate,
        buyerName: e.filters?.find(item => item.name === "buyerName")?.value,
        bookingNumber: e.filters?.find(item => item.name === "bookingNumber")?.value,
        equipmentType: e.selectFilters?.find(item => item.name === "equipmentType")?.value,
        active: e.selectFilters?.find(item => item.name === "active")?.value,
        frequency: e.selectFilters?.find(item => item.name === "frequency")?.value,
        sortDir: e.sortDir
      }));
    } else {
      initialRender.current = false;
    }
  },[]);

  const filterByOptions = useMemo(() => {
    return [
      {
        label: "Buyer Name",
        value: "",
        name: "buyerName",
      },
      {
        label: "Booking Number",
        value: bookingNumber || "",
        name: "bookingNumber",
        numberField: true
      }
    ]
  }, [bookingNumber]);

  return (
    <Grid item mb={4} sx={{
      '& .MuiInputLabel-root.Mui-error':{
        color:'rgba(0, 0, 0, 0.6) !important'
      },
      '& .MuiInput-root.Mui-error:before, .MuiInput-root.Mui-error:after':{
        borderBottom: '1px solid rgba(0, 0, 0, 0.42) !important'
      }
    }}>
      <SortFilter
        filterCallback={handleCallback}
        sortByOption={sortByFields}
        locations={locations}
        filterByOption={filterByOptions}
        filterBySelectOption={filterBySelectOption}
        firstDatePickerLabel="From Date"
        secondDatePickerLabel="To Date"
        defaultSortBy={'startDate'}
        sortDir={'desc'}
        locationOption={[]}
      />
    </Grid>
  )
}

export default withSnackbar(SupplierBookingFilter);
