import React from "react";
import { BookingCard } from "@securspace/securspace-ui-kit";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router";
import { InvalidServiceFeeChargeTypes } from "../constants/securspace-constants";
import { sendBookingConfirmationEmail } from "../../views/requests/booking-requests";
import { withSnackbar } from "../hocs/withSnackbar";
import { adjustBookingTypes, updatePendingBookingActions } from "../../views/supplier-booking/SupplierBooking";
import moment from "moment";

const useStyles = makeStyles(() => ({
  mb:{
    marginBottom: '1rem'
  }
}));

const BookingCardItem = ({booking, headerCells, userIsAdmin, snackbarShowMessage, handleSelectAdjustBooking, handleUpdatePendingBooking}) => {

    const {id, buyerEmail} = booking;

    const history = useNavigate();
    const classes = useStyles();
    const buttons = [];

    const adjustEndDateStatuses = ['Approved', 'Complete', 'Processing-ACH-Payment'];
    const adjustSpacesStatuses = ['Approved'];
    const pendingStatuses = ['Pending', 'Pending Verification Required'];

    const bookingIsInactive = moment(booking.endDate).isBefore(moment()) || booking.active === false;
    
    const sendConfirmationEmail = async (booking) => {
        try {
            await sendBookingConfirmationEmail(booking.id);
            snackbarShowMessage(`Confirmation email sent successfully: ${buyerEmail}`, 'success');
        } catch (error) {
            snackbarShowMessage(`Failed to send confirmation email: ${error}`, 'error')
        }
    }

    const navigateToServiceFeeCreation = (booking) => {
        history(`/booking/${booking.id}/charge-service-fee`, { state: { booking }});
    }

    const shouldShowAdjustEndDate = adjustEndDateStatuses.includes(booking.status) && userIsAdmin && !bookingIsInactive;

    const shouldShowAdjustSpaces = booking.frequency !== 'ONE_TIME' && adjustSpacesStatuses.includes(booking.status) && userIsAdmin && !bookingIsInactive;

    if (booking?.active === 'Active') {
        buttons.push(
            {
                label: 'Resend Confirmation Email',
                onClick: sendConfirmationEmail,
                variant: 'outlined'
            }
        );
    }
    if (!InvalidServiceFeeChargeTypes.includes(booking.status) && !bookingIsInactive) {
        buttons.push(
            {
                label: 'Charge Service Fee',
                onClick: navigateToServiceFeeCreation,
                variant: 'outlined'
            }
        );
    }

    if (shouldShowAdjustEndDate) {
        buttons.push(
            {
                label: 'Adjust End Date',
                onClick: () => handleSelectAdjustBooking(booking, adjustBookingTypes.DATE),
                variant: 'outlined'
            }
        )
    
    }
    if(shouldShowAdjustSpaces){
        buttons.push(
            {
                label: 'Adjust Spaces',
                onClick: () => handleSelectAdjustBooking(booking, adjustBookingTypes.SPACES),
                variant: 'outlined'
            }
        )
    }
    
    if (pendingStatuses.includes(booking.status)) {
        buttons.push(
            {
                label: 'Approve',
                onClick: () => handleUpdatePendingBooking(booking, updatePendingBookingActions.APPROVE),
                variant: "text",
                styles: {
                    color: (theme) => theme.palette.success.main,
                    border: (theme) => `1px solid ${theme.palette.success.main}`,
                }
            },
            {
                label: 'Decline',
                onClick: () => handleUpdatePendingBooking(booking, updatePendingBookingActions.DECLINE),
                variant: 'text',
                styles: {
                    color: (theme) => theme.palette.error.main,
                    border: (theme) => `1px solid ${theme.palette.error.main}`,
                }
            }
        )
    }

    return (
      <BookingCard
        headCells={headerCells}
        rootStyle={classes.mb}
        key={id}
        data={booking}
        buttons={buttons}
        bookingType='supplier'
      />
    );
};

export default withSnackbar(BookingCardItem);
