import React, {useRef, useCallback, useMemo} from "react";
import { Grid } from '@mui/material'
import { SortFilter } from '@securspace/securspace-ui-kit'
import { sortByFields } from './data'
import {withSnackbar} from "../../hocs/withSnackbar";
import useUrlQueryParams from "../../../hooks/useUrlQueryParams";
import useAllLocationNames from "../../../hooks/components/location/useAllLocationNames";

const SupplierInvoicesFilter = React.memo(({
  setSelectedLocation,
  setStartDate,
  setEndDate,
  setBookingNumber,
  setInvoiceNumber,
  setInvoiceType,
  setStatus,
  setSortBy,
  setSortDir,
  snackbarShowMessage,
  defaultStartDate,
  defaultEndDate
}) => {

  const locations = useAllLocationNames(snackbarShowMessage);
  const { invoiceNumber } = useUrlQueryParams();
  const initialRender = useRef(true);

  const handleCallback = useCallback((e) => {
    if (!initialRender.current) {
      setSelectedLocation(e.location);
      setStartDate(e.startDate)
      setEndDate(e.endDate)
      setBookingNumber(e.filters?.find(item => item.name === "bookingNumber")?.value);
      setInvoiceNumber(e.filters?.find(item => item.name === "transactionNumber")?.value);
      setInvoiceType(e.selectFilters?.find(item => item.name === "transactionType")?.value);
      setStatus(e.selectFilters?.find(item => item.name === "status")?.value);
      setSortBy(e.sortBy)
      setSortDir(e.sortDir)
    }
    else {
      initialRender.current = false;
    }
  }, [setSelectedLocation, setStartDate, setEndDate, setBookingNumber, setInvoiceNumber, setInvoiceType, setStatus, setSortBy, setSortDir]);

  const filterByOptions: { label: string, value: string, name: string, numberField?: Boolean}[] = useMemo(() => {
    return [
      {
        label: "Booking Number",
        value: "",
        name: "bookingNumber",
        numberField: true
      },
      {
        label: "Invoice Number",
        value: invoiceNumber || "",
        name: "transactionNumber",
        numberField: true
      }
    ];
  }, [invoiceNumber]);

  return (
    <Grid container mb={4} flexDirection={'column'}>
      <SortFilter
        filterCallback={handleCallback}
        sortByOption={sortByFields}
        defaultEndDate={defaultEndDate}
        defaultStartDate={defaultStartDate}
        locations={locations}
        filterByOption={filterByOptions}
        defaultSortBy={'transactionNumber'}
        sortDir={'desc'}
        locationOption={[]}
      />
    </Grid>
  )
})

export default withSnackbar(SupplierInvoicesFilter);
