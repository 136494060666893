import React, { useRef, useCallback, useMemo } from "react";
import { Grid } from '@mui/material'
import { SortFilter } from '@securspace/securspace-ui-kit';
import moment from "moment";
import { DateFormats } from '../../constants/securspace-constants';
import { sortByFields, filterBySelectOption } from './data'
import { getAllLocationsByBuyer } from "../../location/requests/location-requests";
import useGetRequestBody from "../../../hooks/useGetRequestBody";
import {withSnackbar} from "../../hocs/withSnackbar";

const BuyerInvoicesFilter = React.memo(({
  buyerAccountId,
  setSelectedLocation,
  setStartDate,
  setEndDate,
  setBookingNumber,
  setInvoiceNumber,
  setSortBy,
  setInvoiceType,
  setStatus,
  setSortDir,
  snackbarShowMessage,
  orderNumber,
  transactionNumber
}) => {

  const buyerLocations = useGetRequestBody(getAllLocationsByBuyer, [buyerAccountId], snackbarShowMessage);

  const locations = useMemo(() => {
    return [{id: "", locationName: "All"}, ...buyerLocations];
  }, [buyerLocations]);


  const initialRender = useRef(true);

  const parseFilters = (filterArray) => {
    const bookingNumber = filterArray.find(item => item.name === "bookingNumber")?.value;
    const invoiceNumber = filterArray.find(item => item.name === "invoiceNumber")?.value;
    setBookingNumber(bookingNumber);
    setInvoiceNumber(invoiceNumber);
  }

  const handleCallback = useCallback((e) => {
    if (!initialRender.current) {
      setSortBy(e.sortBy);
      setSelectedLocation(e.location);
      setStartDate(e.startDate);
      setInvoiceType(e.selectFilters?.find(item => item.name === "transactionType")?.value);
      setStatus(e.selectFilters?.find(item => item.name === "status")?.value);
      setEndDate(e.endDate);
      parseFilters(e.filters);
      setSortDir(e.sortDir)
    } else {
      initialRender.current = false;
    }
  }, [setSortBy, setSortDir, setSelectedLocation, setStartDate, setEndDate, setBookingNumber, setInvoiceNumber, setInvoiceType, setStatus, parseFilters])

  const lastMonthDate = moment().add(-1, 'months').format(DateFormats.DAY).toString();
  const todayDate = moment().format(DateFormats.DAY).toString();


  const filterByOptions = useMemo(() => {
    return [
      {name: "bookingNumber", label: "Booking Number", value: orderNumber, numberField: true},
      {name: "invoiceNumber", label: "Invoice Number", value: transactionNumber, numberField: true}
    ];
  }, [orderNumber, transactionNumber]);

  return (
    <Grid item mb={4}>
      <SortFilter
        filterCallback={handleCallback}
        sortByOption={sortByFields}
        locations={locations}
        filterByOption={filterByOptions}
        defaultStartDate={lastMonthDate}
        defaultEndDate={todayDate}
        filterBySelectOption={filterBySelectOption}
        defaultSortBy={"transactionNumber"}
        sortDir={"asc"}
      />
    </Grid>
  )
}
);
export default withSnackbar(BuyerInvoicesFilter);
