import request from "superagent";
import logout from "../components/Logout";
import verbiage from "../components/companyProfile/file-validation-verbiage";

const fileNameIsValid: (fileName: string) => boolean = (fileName) => {
  if (!fileName) return false;
  const name = fileName.trim();
  return name.length > 0 && name.length < 256;
};

export const sanitizeFileName: (fileName: string) => string = (fileName) => {
  const fileNameSplit = fileName.toLowerCase().split('.');

  if (fileNameSplit.length < 2) {
    // Remove leading and trailing whitespace, and replace any non-alphanumeric characters with a hyphen
    return fileName.trim().toLowerCase().replace(/\s+/g, '_').replace(/[^a-zA-Z0-9_-]/g, '-');
  }
  const name = fileNameSplit[0].trim().replace(/\s+/g, '_').replace(/[^a-zA-Z0-9_-]/g, '-');
  const extension = fileNameSplit[fileNameSplit.length-1].trim().replace(/\s+/g, '_').replace(/[^a-zA-Z0-9_-]/g, '-');
  return `${name}.${extension}`;
}

const isSupportedFileType = (file) => {
  let fileType = file['type'];
  let ValidFileTypes = ['image/gif', 'image/jpeg', 'image/png', 'application/pdf'];
  return $.inArray(fileType, ValidFileTypes) >= 0;
}

export const validateFiles = (allUploadFiles) => {
  for (let i = 0; i < allUploadFiles.length; i++) {
    let fileToUpload = allUploadFiles[i];
    let file = fileToUpload.files ? fileToUpload.files[0] : '';
    if (file.size > 20000000) {
      return false
    }
    if (fileToUpload.isImageFile) {
      if (file && !isSupportedFileType(file)) {
        return false;
      }
    }
  }
  return true;
}

/**
 * Validates the file and return an error message if the file is invalid or empty if the file is valid.
 * @param uploadFile The file to validate
 * @returns {string}
 */
export const validateFileWithErrorMessage: (uploadFile: File) => string = (uploadFile) => {
  if (!uploadFile) return verbiage.unrecognizableFile;
  if (uploadFile.size > 20000000) return verbiage.fileTooLarge;
  if (!isSupportedFileType(uploadFile)) return verbiage.fileNotSupported;
  if (!fileNameIsValid(uploadFile.name)) return verbiage.fileNameFormatInvalid;
  return '';
};

export const validateFile = (uploadFile) => {
  if (!uploadFile || uploadFile.size > 20000000) {
    return false
  }
  return isSupportedFileType(uploadFile) && fileNameIsValid(uploadFile.name);
}

export const fetchDownloadFile = (reportUrl: string) => {
  request
    .get(reportUrl)
    .responseType('blob')
    .then(res => {
      const {body, headers} = res;
      const rawFileName = headers['content-disposition'].split('filename=')[1];
      const filename = rawFileName.match(/"([^"]+)"/)[1];

      downloadFileFromBlob(body, filename);
    }, err => {
      if (err.status === 401) {
        logout();
      }
      throw err;
    });
};

const downloadFileFromBlob: (blob: any, fileName: string) => void = (blob, fileName) => {
  if (blob) {
    const url = window.URL.createObjectURL(
      new Blob([blob]),
    );

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);

    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    link.dispatchEvent(clickEvt);
    link.remove();
  }
}