import React, {useCallback, useEffect, useState} from 'react';
import { useParams, useNavigate } from "react-router";
import Busy from '../../components/Busy';
import { Box, Button, Container, Divider, Grid, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useStyles } from './style';
import {
  getErrorMessageForNonStandardAndStandardResponse
} from '../../util/NetworkErrorUtil';
import {withSnackbar} from "../../components/hocs/withSnackbar";
import {verbiage} from "./booking-make-payment-verbiage";
import BuyerPaymentMethodSelect from "../../components/paymentMethods/buyerPaymentMethodSelect/BuyerPaymentMethodSelect";
import TransactionsList from "../../components/payments/failedPayments/TransactionsList";
import BookingPayment from "../../components/payments/bookingPayment/BookingPayment";
import { requestBookingById } from "../../components/payments/payment-requests";
import { BuyerBookingTabs } from "../../components/bookingsOverview/data";
import { updatePaymentMethod } from '../../components/bookedSpaces/request/booked-spaces-requests';
import { getRentalAgreementURL } from '../../util/BookingUtil';
import { toast } from "react-toastify";

const BookingMakePayment: (props) => JSX.Element = ({account, snackbarShowMessage}) => {
  const classes = useStyles();
  const {bookingId} = useParams();
  const navigate = useNavigate();

  const [paymentMethodId, setPaymentMethodId] = useState([]);
  const [booking, setBooking] = useState([]);
  const [failedPaymentsTotal, setFailedPaymentsTotal] = useState({count: 0, totalCharge: 0 });

  const loadBooking = useCallback(() => {
    if(bookingId) {
      Busy.set(true)
      requestBookingById(bookingId)
        .then((response) => {
            setBooking(response?.body);
            Busy.set(false);
        })
        .catch((error) => {
          snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(error), 'error', 15000);
          Busy.set(false);
        })
    }
  }, [bookingId, setBooking]);

  useEffect(() => {
      loadBooking();
  }, [loadBooking]);

  const returnToBookings = () => {
    navigate(`/bookings?status=${BuyerBookingTabs.PAYMENT_FAILED}`);
  };

  const handleMakePayment = () => {
    Busy.set(true)
    const bookingData = {
          id: booking.id,
          buyerAccountId: account.id,
          paymentMethodId: paymentMethodId,
          tosDocUrl: getRentalAgreementURL(booking.supplierLegalAgreementFileName)
        }
    updatePaymentMethod(bookingData)
      .then(({body}) => {
        const {orderNumber} = body;
        // use toast here instead of snackbar so that the message still displays when we redirect to the booking page
        toast.success(`Successfully updated payment method for booking ${orderNumber}.`);
        navigate(`/bookings?bookingNumber=${orderNumber}`);
      }) 
      .catch((err) => snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(err), "error"))
      .finally(() => Busy.set(false));
  }

  return (
    <>
        <Box component='section' className={classes.mainSection}>
          <Container className={classes.container}>
            <Box component='div' className={classes.section}>
              <header>
                <Typography color={'textPrimary'} component={'h1'} className={classes.heading}>
                  <Button sx={{ minWidth: '0em', padding: '0em', mr: 0.4 }} variant='text' onClick={returnToBookings} >
                    <ArrowBackIcon />
                  </Button>
                  {verbiage.pageTitle}
                </Typography>
              </header>
              <Divider variant='fullWidth' className={classes.lpDivider}/>
              <Grid item container className={classes.mainContainer}>
                <Grid item container direction={'column'} className={classes.lpListingInfoSection} xs>
                  <BuyerPaymentMethodSelect
                    account={account}
                    setSelectedPaymentMethod={setPaymentMethodId}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <TransactionsList
                    account={account}
                    bookingId={bookingId}
                    paymentMethodId={paymentMethodId}
                    setPaymentsTotal={setFailedPaymentsTotal}
                  />
                </Grid>
                <Grid item xs={12}>
                  <BookingPayment
                    account={account}
                    booking={booking}
                    paymentTotals={failedPaymentsTotal}
                    paymentMethodId={paymentMethodId}
                    handleMakePayment={handleMakePayment}
                  />
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
    </>
  );
};

export default withSnackbar(BookingMakePayment);
