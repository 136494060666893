import React, {useState} from 'react';
import { Button, Divider, Grid, TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";
import {
  formatPhoneNumber,
  parseFormattedPhoneNumber,
  validateEmail, validateName,
  validatePhoneNumber
} from "../../../util/ValidationUtils";
import { Person } from "@mui/icons-material";
import AddressLookup from "../AddressLookup";
import { confirmTermsOfService, createUpdateAccount } from "../../user/request/user-requests";
import { requestLoggedInAuthoritySource } from "../../../services/session/session-requests";
import Busy from "../../Busy";
import { createLocationFromAddress } from "../../../util/AddressUtil";
import { withSnackbar } from "../../hocs/withSnackbar";
import ChargeModelButton from '../ChargeModelButton';
import PayoutScheduleSelect from '../PayoutScheduleSelect';
import classNames from "classnames";
import {DateFormats} from "../../constants/securspace-constants";
import TermsAndPolicyCheckbox from "../../TermsAndPolicyCheckbox";
import UploadLegalAgreement from "../UploadLegalAgreement";
import {sanitizeFileName, validateFileWithErrorMessage} from "../../../util/FileUtils";
import { uploadLegalAgreement } from "../../checkin/request/file-upload-requests";
import ViewLegalAgreement from "../ViewLegalAgreement";
import moment from "moment";
import { isSupplierAccount } from "../../../util/AccountUtils";
import useStyles from "./style";

const EditCompanyProfile = ({ account, handleLogout, snackbarShowMessage, handleAccountUpdated }) => {
  const [companyToEdit, setCompanyToEdit] = useState(Object.assign({}, account));
  const [lookupAddress, setLookupAddress] = useState({
    lookupLocation: createLocationFromAddress(account),
    addressLine1: account.addressLine1,
    addressLine2: account.addressLine2,
    city: account.city,
    state: account.state,
    zip: account.zip,
    addressLatitude: account.addressLatitude,
    addressLongitude: account.addressLongitude,
  });
  // Error Messages
  const [companyNameErrorMsg, setCompanyNameErrorMsg] = useState('');
  const [emailErrorMsg, setEmailErrorMsg] = useState('');
  const [phoneNumberErrorMsg, setPhoneNumberErrorMsg] = useState('');
  const [lookupAddressErrorMsg, setLookupAddressErrorMsg] = useState('');
  const [firstNameErrorMsg, setFirstNameErrorMsg] = useState('');
  const [lastNameErrorMsg, setLastNameErrorMsg] = useState('');
  const [legalAgreementErrorMsg, setLegalAgreementErrorMsg] = useState('');

  const [useCalendarDays, setUseCalendarDays] = useState(account.useCalendarDays);
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const [legalAgreement: File, setLegalAgreement] = useState();
  const [legalAgreementFileName, setLegalAgreementFileName] = useState(account.supplierLegalAgreementFileName);

  const classes = useStyles();

  const simpleTextValidation = (value, label) => {
    if (!value || !value.trim()) {
      return `Please enter a ${label}`;
    }
    return '';
  };

  const simpleNameValidation = (value, label) => {
    if (!value || !value.trim()) {
      return `Please enter a ${label}`;
    }
    return validateName(value) ? '' : `${label} can contain only spaces, hyphens, and alphanumeric characters`;
  };

  const emailValidation = (value) => {
    if (!value || !value.trim()) {
      return 'Please enter an email address';
    }
    return validateEmail(value) ? '' : 'Email address is invalid';
  };

  const phoneNumberValidation = (value) => {
    return validatePhoneNumber(value) ? '' : 'Phone number must be 10 digits';
  };

  const handleSimpleTextChange = (event) => {
    setChangesMade(true);
    const name = event.target.name;
    const value = event.target.value;

    switch (name) {
      case 'companyName':
        setCompanyToEdit({ ...companyToEdit, companyName: value });
        setCompanyNameErrorMsg(simpleTextValidation(value, 'Company Name'));
        break;
      case 'email':
        setCompanyToEdit({ ...companyToEdit, email: value });
        setEmailErrorMsg(emailValidation(value));
        break;
      case 'phoneNumber':
        setCompanyToEdit({ ...companyToEdit, phoneNumber: parseFormattedPhoneNumber(value) });
        setPhoneNumberErrorMsg(phoneNumberValidation(value.replace(/[^0-9]*/g,'')));
        break;
      case 'companyDescription':
        setCompanyToEdit({ ...companyToEdit, companyDescription: value });
        break;
      case 'firstName':
        setCompanyToEdit({ ...companyToEdit, firstName: value });
        setFirstNameErrorMsg(simpleNameValidation(value, 'First Name'));
        break;
      case 'lastName':
        setCompanyToEdit({ ...companyToEdit, lastName: value });
        setLastNameErrorMsg(simpleNameValidation(value, 'Last Name'));
        break;
      default:
        break;
    }
  };

  const handleAddressChange = (lookupAddressObject) => {
    setChangesMade(true);
    // If the user selects a location then clear the error message
    // See validation in form submit handler
    if (lookupAddressObject.lookupLocation && (lookupAddressObject.addressLatitude || lookupAddressObject.addressLongitude)) {
      setLookupAddressErrorMsg('');
    }
    // Clear lat and lng every time the user types in the lookupLocation field as they invalidate the lat and lng.
    // Once the user selects an address from the lookup, then the received lat and lng will be valid.
    setLookupAddress({
      ...lookupAddressObject,
      addressLatitude: lookupAddressObject.addressLatitude || null,
      addressLongitude: lookupAddressObject.addressLongitude || null,
    });
  };

  const handleChangePayoutSchedule = (e) => {
    setChangesMade(true);
    setCompanyToEdit({ ...companyToEdit, payoutSchedule: e.target.value })
  }

  const handleChangeUseCalendarDays = (value) => {
    setChangesMade(true);
    setUseCalendarDays(value);
  }

  const handleTermsCheckbox = (e) => {
    setHasAcceptedTerms(e.target.checked);
  }

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setChangesMade(true);
    if (!file) {
      setLegalAgreement(null);
      setLegalAgreementFileName('');
      setLegalAgreementErrorMsg('');
      return;
    }
    const newName = sanitizeFileName(file.name);
    const newFile = new File([file], newName, { type: file.type });
    const validationMessage = validateFileWithErrorMessage(newFile);
    if (validationMessage) {
      setLegalAgreementErrorMsg(validationMessage);
      return;
    }
    setLegalAgreement(newFile);
    setLegalAgreementFileName(newFile.name);
    setLegalAgreementErrorMsg('');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const lookupAddressInvalid = !lookupAddress.lookupLocation || !lookupAddress.addressLatitude || !lookupAddress.addressLongitude;
    if (
      companyNameErrorMsg ||
      emailErrorMsg ||
      phoneNumberErrorMsg ||
      firstNameErrorMsg ||
      lastNameErrorMsg ||
      legalAgreementErrorMsg
    ) {
      return;
    }
    if (lookupAddressInvalid) {
      setLookupAddressErrorMsg('Please select an address from the drop down menu');
      return;
    }

    const handleResponseError = (error, message, number) => {
      if (error.status === 401) {
        handleLogout();
      } else {
        snackbarShowMessage(message, 'error', number);
      }
      setHasAcceptedTerms(false);
    }

    const body = {
      activated: account.activated,
      addressLatitude: lookupAddress.addressLatitude,
      addressLine1: lookupAddress.addressLine1,
      addressLine2: lookupAddress.addressLine2,
      addressLongitude: lookupAddress.addressLongitude,
      billingFeePercentage: account.billingFeePercentage,
      city: lookupAddress.city,
      companyDescription: companyToEdit.companyDescription?.trim() ?? null,
      companyName: companyToEdit.companyName.trim(),
      email: companyToEdit.email,
      feePercentage: account.feePercentage,
      firstName: companyToEdit.firstName.trim(),
      id: account.id,
      lastName: companyToEdit.lastName.trim(),
      listingImageFileName: account.listingImageFileName,
      payoutSchedule: companyToEdit.payoutSchedule,
      phoneNumber: companyToEdit.phoneNumber,
      securspaceServiceFeePercentage: account.securspaceServiceFeePercentage,
      state: lookupAddress.state,
      subscriptionEffectiveDate: moment(account.subscriptionEffectiveDate).isValid() ? moment(account.subscriptionEffectiveDate).format(DateFormats.DAY) : account.subscriptionEffectiveDate,
      subscriptionFee: account.subscriptionFee,
      subscriptionType: account.subscriptionType,
      supplierLegalAgreementFileName: legalAgreementFileName,
      type: account.type,
      useCalendarDays: useCalendarDays,
      username: account.username,
      zip: lookupAddress.zip,
    };

    const tosAgreedAccount = {
      companyName: body.companyName,
      firstName: body.firstName,
      lastName: body.lastName,
      phoneNumber: body.phoneNumber,
      email: body.email,
      password: "",
      confirmPassword: ""
    }

    Busy.set(true);
    confirmTermsOfService(account.id, tosAgreedAccount).then(() => {
      createUpdateAccount(body).then(() => {
        requestLoggedInAuthoritySource().then((resp) => {
          handleAccountUpdated(resp.body);
          snackbarShowMessage('Account updated successfully', 'success', 8000);
        }).catch(error => {
          handleResponseError(error, 'An error occurred while updating your account. Please contact support if this issue persists.', 10000);
        }).finally(() => {
          setHasAcceptedTerms(false);
          setChangesMade(false);
        });
      }).catch(error => {
        handleResponseError(error, 'An error occurred while updating your account. Please contact support if this issue persists.', 15000);
      });
    }).catch(error => {
      handleResponseError(error, 'Terms Of Service failed to upload, please try again.', 8000);
    }).finally(() => Busy.set(false));

    if (legalAgreement) {
      uploadLegalAgreement(legalAgreement, legalAgreement.name, legalAgreement.type).then(() => {
        snackbarShowMessage('Legal Agreement uploaded successfully', 'success', 8000);
      }).catch(() => {
        snackbarShowMessage('Legal Agreement failed to upload, please try again', 'error', 8000);
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} noValidate className={'w-100'}>
      <Grid container direction={'column'} className={classes.rowGap10}>
        <TextField
          variant='standard'
          value={companyToEdit.companyName}
          label={'Company Name'}
          name={'companyName'}
          onChange={handleSimpleTextChange}
          error={!!companyNameErrorMsg}
          helperText={companyNameErrorMsg ? companyNameErrorMsg : ''}
          placeholder={'Please enter a company name'}
          required
          fullWidth
        />
        <TextField
          variant='standard'
          value={companyToEdit.email}
          label={'Email'}
          name={'email'}
          onChange={handleSimpleTextChange}
          error={!!emailErrorMsg}
          helperText={emailErrorMsg ? emailErrorMsg : ''}
          placeholder={'Please enter an email address'}
          required
          fullWidth
        />
        <TextField
          variant='standard'
          value={formatPhoneNumber(companyToEdit.phoneNumber)}
          label={'Phone Number'}
          name={'phoneNumber'}
          type={'tel'}
          onChange={handleSimpleTextChange}
          error={!!phoneNumberErrorMsg}
          helperText={phoneNumberErrorMsg || ''}
          placeholder={'Please enter a phone number'}
          required
          fullWidth
        />
        <TextField
          variant='standard'
          value={companyToEdit.companyDescription || ''}
          label={'Company Description'}
          name={'companyDescription'}
          onChange={handleSimpleTextChange}
          placeholder={'Please enter a company description'}
          helperText={'Describe your company in a few words'}
          multiline
          fullWidth
        />
        {
          isSupplierAccount(account) && <>
            <UploadLegalAgreement classes={classNames(classes.rowGap10, classes.marginTop20)} handleFileUpload={handleFileUpload} error={!!legalAgreementErrorMsg} helperText={legalAgreementErrorMsg || ''} />
            { !legalAgreementErrorMsg && <ViewLegalAgreement account={account} legalAgreementFile={legalAgreement} classes={classNames(classes.rowGap10)}/> }
            <PayoutScheduleSelect classes={classNames(classes.rowGap10, classes.marginTop20)} selection={companyToEdit.payoutSchedule} handleChangeSelection={handleChangePayoutSchedule} />
            <ChargeModelButton classes={classes} useCalendarDays={useCalendarDays} setUseCalendarDays={handleChangeUseCalendarDays} />
          </>
        }
        <Grid item component={'section'} className={classes.rowGap10}>
          <Typography variant={'h6'} color={'textPrimary'}>Location</Typography>
          <AddressLookup
            name={'address'}
            value={lookupAddress.lookupLocation}
            label={'Address'}
            placeholder={'Please enter your company address'}
            onChange={handleAddressChange}
            error={!!lookupAddressErrorMsg}
            helperText={lookupAddressErrorMsg}
          />
        </Grid>
        <Grid
          item
          container
          direction={'column'}
          component={'section'}
          className={classes.rowGap10}
        >
          <Grid item container component={'header'} className={classes.columnGap5} alignContent={'center'}>
            <Person color={'action'} />
            <Typography variant={'h6'}>Personal Information</Typography>
          </Grid>
          <TextField
            variant='standard'
            value={companyToEdit.firstName}
            label={'First Name'}
            name={'firstName'}
            onChange={handleSimpleTextChange}
            error={!!firstNameErrorMsg}
            helperText={firstNameErrorMsg || ''}
            placeholder={'Please enter your first name'}
            required
            fullWidth
          />
          <TextField
            variant='standard'
            value={companyToEdit.lastName}
            label={'Last Name'}
            name={'lastName'}
            onChange={handleSimpleTextChange}
            error={!!lastNameErrorMsg}
            helperText={lastNameErrorMsg || ''}
            placeholder={'Please enter your last name'}
            required
            fullWidth
          />
        </Grid>
        <Divider variant={'fullWidth'} className={classes.marginTop20} />
        <Grid item>
          <TermsAndPolicyCheckbox
            checked={hasAcceptedTerms}
            onChange={handleTermsCheckbox}
            name={"agreementAccepted"}
            disabled={!changesMade}
          />
        </Grid>
        <Button
          type={'submit'}
          variant={'contained'}
          color={'secondary'}
          fullWidth
          className={classes.marginTop20}
          disabled={!hasAcceptedTerms}
        >
          Save Changes
        </Button>
      </Grid>
    </form>
  );
};

EditCompanyProfile.propTypes = {
  account: PropTypes.object.isRequired,
  handleLogout: PropTypes.func.isRequired,
  handleAccountUpdated: PropTypes.func.isRequired,
};

export default withSnackbar(EditCompanyProfile);
