const headerCells = [
  {
    id: 'transactionNumber',
    label: 'Invoice Number'
  },
  {
    id: 'buyerAmount',
    label: 'Amount'
  },
  {
    id: 'transactionType',
    label: 'Invoice Type'
  },
  {
    id: 'serviceDates',
    label: 'Service Dates'
  },
  {
    id: 'bookingNumber',
    label: 'Booking'
  },
  {
    id: 'assetType',
    label: 'Equipment Type'
  },
  {
    id: 'paymentMethodDescription',
    label: 'Payment Method'
  },
  {
    id: 'paymentType',
    label: 'Payment Type'
  },
  {
    id: 'numberOfSpaces',
    label: 'Spaces'
  },
  {
    id: 'createdOn',
    label: 'Invoice Created date'
  },
  {
    id: 'paymentCreatedOn',
    label: 'Payment Initiated Date'
  },
  {
    id: 'paymentCompletedOn',
    label: 'Process Completed Date'
  },
  {
    id: 'locationName',
    label: 'Location'
  },
  {
    id: 'supplierCompanyName',
    label: 'Supplier'
  },
  {
    id: 'serviceFeeType',
    label: 'Service Charge Type'
  },
  {
    id: 'status',
    label: 'Status'
  }
];
const sortByFields = [
  { label: "Created On", value: "createdOn" },
  { label: "Invoice Number", value: "transactionNumber" },
  { label: "Invoice Type", value: "type" },
  { label: "Amount", value: "grossAmount" },
  { label: "Location", value: "locationName" },
  { label: "Supplier", value: "supplierCompanyName" },
  { label: "Asset Type", value: "assetType" },
  { label: "Booking", value: "bookingNumber" }
];
const filterByOptions = [
  {
    label: "Booking Number",
    value: "",
    name: "bookingNumber",
    numberField: true
  },
  {
    label: "Invoice Number",
    value: "",
    name: "transactionNumber",
    numberField: true
  }
];
const invoiceTypeOptions = [
  { value: '', label: 'All' },
  { value: 'BOOKING_CHARGE', label: 'Booking Payment' },
  { value: 'CANCELLATION_REFUND', label: 'Cancellation Refund' },
  { value: 'OVERAGE_CHARGE', label: 'Overage Payment' },
  { value: 'DATE_ADJUST_CHARGE', label: 'Date Adjust Payment' },
  { value: 'DATE_ADJUST_REFUND', label: 'Date Adjust Refund' },
  { value: 'OVERSTAY_CHARGE', label: 'Overstay Payment' },
  { value: 'ADD_SPACE_CHARGE', label: 'Add Space Charge' },
  { value: 'SPACE_REMOVED_REFUND', label: 'Space Removed Refund' },
  { value: 'DAILY_OVERAGE_CHARGE', label: 'Daily Overage Payment' },
  { value: 'AGS_FEE', label: 'Ags Fee' },
  { value: 'SERVICE_CHARGE', label: 'Service Charge' },
  { value: 'BILLING_CORRECTION_REFUND', label: 'Billing Correction Refund' },
];
const statusOptions = [
  { value: '', label: 'All' },
  { value: 'BOOKING_DECLINED', label: 'Booking Declined' },
  { value: 'CHARGE_PENDING', label: 'Charge Pending' },
  { value: 'INCOMPLETE', label: 'Booking Incomplete' },
  { value: 'NEEDS_CUSTOMER_APPROVAL', label: 'Awaiting Customer Approval' },
  { value: 'NOT_APPROVED', label: 'Approval Pending' },
  { value: 'REFUND_REQUESTED', label: 'Refund Requested' },
  { value: 'REFUND_DENIED', label: 'Refund Denied' },
  { value: 'MANUAL_PAYMENT', label: 'Manual Payment' },
  { value: 'PAYMENT_FAILED', label: 'Payment Failed' },
  { value: 'PAYMENT_PENDING', label: 'Payment Pending' },
  { value: 'PAYMENT_SUCCEEDED', label: 'Complete' },
  { value: 'UNCOLLECTIBLE', label: 'Uncollectible' },
  { value: 'CANCELLED', label: 'Cancelled' },
  { value: 'DECLINED', label: 'Declined' },
];
const filterBySelectOption = [
  {
    label: "Invoice Type",
    value: "",
    name: "transactionType",
    option: invoiceTypeOptions
  },
  {
    label: "Status",
    value: "",
    name: "status",
    option: statusOptions
  },
];

const propertiesToCheck = ['transactionNumber', 'buyerAmount', 'paymentType', 'paymentMethodDescription', 'transactionType', 'numberOfSpaces', 'startDate', 'createdOn', 'paymentCreatedOn', 'paymentCompletedOn', 'status', 'bookingNumber', 'locationName', 'supplierCompanyName', 'assetType'];

export {
  headerCells,
  sortByFields,
  filterByOptions,
  filterBySelectOption,
  propertiesToCheck
}