import React, {memo} from 'react';
import {Grid, useMediaQuery} from "@mui/material";
import DownloadCSVButton from "../../report/export/DownloadCSVButton";
import {
  getSupplierSpaceUsageReportUrl
} from "../../../requests/space-utilization-requests";
import {withSnackbar} from "../../hocs/withSnackbar";
import DownloadSpaceUsageCSVButton from '../DownloadSpaceUsageCSVButton';

const SpaceUsageExportCSVs = (props: {
  item?: boolean,
  accountId: string,
  query: {
    startDate: string,
    endDate: string,
    locationId: string,
    locationName: string,
    bookingNumber: string,
    overageInvoiceNumber: string,
    sortBy: string,
    sortDir: string,
  },
  snackbarShowMessage: (message: Object, severity: String, duration: Number, position?: {}) => void,
}) => {
  const { item, accountId, query, snackbarShowMessage } = props;
  const isMd = useMediaQuery(theme => theme.breakpoints.up('md'));

  const spaceUtilizationReportUrl = getSupplierSpaceUsageReportUrl(accountId, query);

  const handleDownloadReportError = () => {
    snackbarShowMessage('Failed to download report', 'error');
  };

  const handleDownloadGroupSummaryError = () => {
    snackbarShowMessage('Failed to download group summary', 'error');
  };

  let reportQuery = Object.assign({}, query);
  reportQuery.accountId = accountId;

  return (
    <Grid item={item} container gap={1} justifyContent={isMd ? 'flex-end' : 'space-between'}>
      <Grid item>
        <DownloadCSVButton reportUrl={spaceUtilizationReportUrl} title={'Download Report'} onError={handleDownloadReportError}/>
      </Grid>
      <DownloadSpaceUsageCSVButton 
        onError={handleDownloadGroupSummaryError} 
        isBuyer={false} 
        query={reportQuery} 
      />
    </Grid>
  )
}

export default withSnackbar(memo(SpaceUsageExportCSVs));