import React, {useEffect, useState} from 'react';
import {withSnackbar} from "../../hocs/withSnackbar";
import {Grid} from "@mui/material";
import {GroupSummaries, GroupSummariesLoader} from "@securspace/securspace-ui-kit";
import {requestBuyerInvoicesSummary} from "./requests/buyer-invoices-requests";
import {getErrorMessageForNonStandardAndStandardResponse} from "../../../util/NetworkErrorUtil";
import {formatCurrencyValue} from "../../../util/PaymentUtils";
import {omit} from "underscore";

const BuyerInvoicesGroupSummary = ({query, snackbarShowMessage}) => {

  const [loading, setLoading] = useState(false);
  const [groupSummaryFields, setGroupSummaryFields] = useState([]);

  useEffect(() => {
    if (!query) return;
    setLoading(true);
    // Remove unused fields
    const filteredQuery = omit(query, ['sortBy', 'sortDir', 'page', 'size']);
    requestBuyerInvoicesSummary(filteredQuery)
      .then(response => {
        setGroupSummaryFields([
          {title: 'Base Charges', value: formatCurrencyValue(response.body.buyerAmount, true)},
          {title: 'Processor Fees', value: formatCurrencyValue(response.body.paymentProcessorFees, true)},
          {title: 'Taxes', value: formatCurrencyValue(response.body.transactionTaxAmount, true)},
          {title: 'Total Charges', value: formatCurrencyValue(response.body.grossAmount, true)}
        ]);
      })
      .catch(error => {
        snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(error), 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [query, snackbarShowMessage]);

  return (
    <Grid item xs={12}>
      {
        loading ? <GroupSummariesLoader /> :
          <GroupSummaries groupSummaryFields={groupSummaryFields} />
      }
    </Grid>
  );
};

export default withSnackbar(BuyerInvoicesGroupSummary);