import {Theme} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles: (theme: Theme) => {
  columnGap5: CSSStyleSheet,
  rowGap10: CSSStyleSheet,
  marginTop20: CSSStyleSheet,
  marginBottom20: CSSStyleSheet,
  labelText: CSSStyleSheet,
  button: CSSStyleSheet,
  buttonPadding: CSSStyleSheet,
} = makeStyles((theme) => ({
  columnGap5: {
    columnGap: '0.36rem',
  },
  rowGap10: {
    rowGap: '0.71rem',
  },
  marginTop20: {
    marginTop: '1.43rem',
  },
  marginBottom20: {
    marginBottom: '1.43rem',
  },
  labelText: {
    fontSize: '.85rem',
  },
  button: {
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      minWidth: '10.71em !important',
    }
  },
  buttonPadding: {
    padding: 6,
    width: "100%",
    display: "flex",
    gap: 10,
    flexWrap: 'wrap',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      flexWrap: 'nowrap'
    }
  },
}));

export default useStyles;