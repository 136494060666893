import React, { useState, useEffect } from 'react'
import { Typography, Grid, Button } from "@mui/material";
import moment from 'moment'
import { Pagination, InfoCardLoader } from '@securspace/securspace-ui-kit'
import {
  BuyerInterChangesFilters,
  BuyerInterChangesTable,
  BuyerInterChangeImageModal,
  BuyerInterChangeExportCSVs
} from '../../components/interchanges';
import { withSnackbar } from '../../components/hocs/withSnackbar';
import { getErrorMessageForStandardResponse } from '../../util/NetworkErrorUtil';
import { getAllInterChanges } from '../../components/interchanges/requests/buyer-interchanges-requests';
import {
  AssetType,
  AssetTypeLabel,
  DateFormats,
  InterchangeActivityTypeLabel
} from '../../components/constants/securspace-constants';
import { styles } from '../../components/interchanges/styles';
import { expandAllItems, toggleItem } from '../invoices/BuyerInvoicesReport';
import useUrlQueryParams from '../../hooks/useUrlQueryParams';

const BuyerInterChangesReport = ({ account, snackbarShowMessage, filterSelectOptions }) => {

  const {initSupplierName, initEquipmentNumber, initDriverName} = useUrlQueryParams();

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(50);
  const [totalNumberResults, setTotalNumberResults] = useState(0);
  const [expandedItems, setExpandedItems] = useState({});
  const [expandedAll, setExpandedAll] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState();
  const [sortBy, setSortBy] = useState('');
  const [supplierName, setSupplierName] = useState(initSupplierName)
  const [equipmentNumber, setEquipmentNumber] = useState(initEquipmentNumber)
  const [driverName, setDriverName] = useState(initDriverName);
  const [sortDir, setSortDir] = useState('')
  const [equipmentType, setEquipmentType] = useState('')
  const [images, setImages] = useState([])
  const [startDate, setStartDate] = useState(moment().add(-1, 'months').format(DateFormats.DAY));
  const [endDate, setEndDate] = useState(moment().format(DateFormats.DAY));
  const [interchanges, setInterchanges] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [query: {
    buyerAccountId: string,
    page: number,
    size: number,
    startDate: string,
    endDate: string,
    sortBy: string,
    locationId: string,
    locationName: string,
    supplierName: string,
    equipmentType: string,
    equipmentNumber: string,
    driverName: string,
    sortDir: string,
  }, setQuery] = useState();

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  const isInterChanges = interchanges && interchanges?.length > 0;

  useEffect(() => {
    const { id } = account;
    if (id) {
      setLoading(true)
      setQuery({
        buyerAccountId: id,
        page: page,
        size: size,
        startDate: startDate,
        endDate: endDate,
        sortBy: sortBy,
        locationId: selectedLocation?.id,
        locationName: selectedLocation?.locationName,
        supplierName: supplierName?.trim(),
        equipmentType: equipmentType === AssetType.ALL ? null : equipmentType,
        equipmentNumber: equipmentNumber?.trim(),
        driverName,
        sortDir
       });
    }
  }, [account, page, size, startDate, endDate, selectedLocation, equipmentNumber, equipmentType, supplierName, driverName, sortBy, sortDir]);

  useEffect(() => {
    if (query) {
      getData()
        .then(({ body }) => {
          const { content, count } = body;
          const data = content?.map((item) => {
            return {
              ...item,
              activity: InterchangeActivityTypeLabel[item.activity] || item.activity,
              assetType: AssetTypeLabel[item.assetType] || item.assetType,
            };
          });
          setInterchanges(data)
          setTotalNumberResults(count);
          setTotalNumberResults(count);
          setExpandedItems({})
        })
        .catch(err => {
          snackbarShowMessage(getErrorMessageForStandardResponse(err), "error", 15000)
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [query]);

  const getData = async () => {
    return await getAllInterChanges(query);
  };

  const handlePageChange = (_, selectedPage) => {
    setPage(selectedPage)
  }

  const handleSizeChange = (event) => {
    setSize(event.target.value)
  }

  
const handleToggle = (id) => toggleItem(id, setExpandedItems);
const handleExpandAll = () => expandAllItems(interchanges, 'id', setExpandedItems, expandedAll, setExpandedAll);


  return (
    <Grid item container flexDirection={'column'}>
      <Typography variant='h5' component='h1' mb={4}>Interchanges</Typography>
      <BuyerInterChangesFilters
        buyerAccountId={account.id}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        interchanges={interchanges}
        setSortBy={setSortBy}
        setSelectedLocation={setSelectedLocation}
        setEquipmentNumber={setEquipmentNumber}
        setEquipmentType={setEquipmentType}
        setSupplierName={setSupplierName}
        setDriverName={setDriverName}
        setSortDir={setSortDir}
        supplierName={supplierName}
        equipmentNumber={equipmentNumber}
        driverName={driverName}
        filterSelectOptions={filterSelectOptions} 
      />
      {isInterChanges && <Grid item container sx={styles.itemsAlign}>
      <Grid sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 2,
        }} item>
        <Typography variant='body2' sx={{
          textTransform: 'uppercase',
          color: '#000'
        }} component='p'>
          {selectedLocation?.locationName || 'All'} (SHOWING {interchanges.length} RECORDS IN GROUP)
        </Typography>
        <Button onClick={Object.keys(expandedItems).length > 0 ? () => {
                        setExpandedItems({})
                        setExpandedAll(false)
                    } : handleExpandAll} variant='text'>
                        {Object.keys(expandedItems).length > 0 ? 'Collapse All' : 'Expand All'}
                    </Button>
          </Grid>
        <BuyerInterChangeExportCSVs
          item
          query={query}
        />
      </Grid>}
      {
        loading ? [...Array(10).keys()].map((_, index) => (
          <InfoCardLoader key={index} />
        )) : <BuyerInterChangesTable
        interchanges={interchanges}
        setImages={setImages}
        handleOpen={handleOpen}
        locationId={selectedLocation?.id || ''}
        supplierName={supplierName}
        equipmentType={equipmentType}
        equipmentNumber={equipmentNumber}
        sortBy={sortBy}
        expandedItems={expandedItems}
        handleToggle={handleToggle}
      />
      }

      {isInterChanges && <Grid item>
        <Pagination
          count={totalNumberResults}
          rowsPerPage={size}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleSizeChange}
          variant="tablePagination" />
      </Grid>}
      <BuyerInterChangeImageModal
        openModal={openModal}
        handleClose={handleClose}
        images={images}
      />
    </Grid>
  )
}

export default withSnackbar(BuyerInterChangesReport)
