import React from 'react';
import ReportDataSummary from "../../report/ReportDataSummary";
import {getBuyerInvoicesReportUrl} from "./requests/buyer-invoices-requests";
import DownloadCSVButton from "../../report/export/DownloadCSVButton";
import {withSnackbar} from "../../hocs/withSnackbar";
import {getErrorMessageForNonStandardAndStandardResponse} from "../../../util/NetworkErrorUtil";

type BuyerInvoicesReportDataSummaryProps = {
  accountId: string,
  query: {
    startDate?: string,
    endDate?: string,
    locationId?: string,
    bookingNumber?: string,
    invoiceNumber?: string,
    transactionType?: string,
    status?: string,
    sortBy?: string,
    sortDir?: string
  },
  count: number,
  title: string,
  setExpandedItems: (items: Object) => void,
  setExpandedAll: (isExpanded: boolean) => void,
  isShowCollapseButton?: boolean,
  handleExpandAll: (event: MouseEvent) => void,
  expandedItems: Object,
  snackbarShowMessage: (message: Object, severity: String, duration: Number, position?: {}) => void,
}

const BuyerInvoicesReportDataSummary: (props: BuyerInvoicesReportDataSummaryProps) => JSX.Element = (
  {
    accountId,
    query,
    count,
    title,
    setExpandedItems,
    setExpandedAll,
    isShowCollapseButton = false,
    handleExpandAll,
    expandedItems,
    snackbarShowMessage
  }
) => {
  return (
    <ReportDataSummary
      count={count}
      title={title}
      setExpandedItems={setExpandedItems}
      setExpandedAll={setExpandedAll}
      isShowCollapseButton={isShowCollapseButton}
      handleExpandAll={handleExpandAll}
      expandedItems={expandedItems}
    >
      <DownloadCSVButton
        title={'Download Report'}
        reportUrl={getBuyerInvoicesReportUrl(accountId, query)}
        onError={(error) => snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(error, 'Failed to download report'))}
      />
    </ReportDataSummary>

  )
}

export default withSnackbar(BuyerInvoicesReportDataSummary);