import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { Pagination, InfoCardLoader } from '@securspace/securspace-ui-kit'
import { withSnackbar } from '../../components/hocs/withSnackbar';
import {
    BuyerInvoicesFilter,
    BuyerInvoicesTable
} from '../../components/invoices/buyer-invoice';
import { getServiceFeeDisplayValue } from '../../components/invoices/serviceFeeHelper.js';
import { getBuyerInvoices } from '../../components/invoices/buyer-invoice/requests/buyer-invoices-requests';
import { getErrorMessageForStandardResponse } from '../../util/NetworkErrorUtil';
import { formatCurrencyValue } from '../../util/PaymentUtils';
import { BookingTransactionTypeLabel, DateFormats } from '../../components/constants/securspace-constants';
import getDisplayValue from '../../components/BuyerTransactionStatus';
import { propertiesToCheck } from '../../components/invoices/buyer-invoice/data';
import moment from 'moment'
import useUrlQueryParams from '../../hooks/useUrlQueryParams';
import BuyerInvoicesReportDataSummary from "../../components/invoices/buyer-invoice/BuyerInvoicesReportDataSummary";
import BuyerInvoicesGroupSummary from "../../components/invoices/buyer-invoice/BuyerInvoicesGroupSummary";
import {formatDateMMDDyyyy} from "../../util/DateUtils";

export const toggleItem = (id, setExpandedItems) => {
    setExpandedItems((prevExpandedItems) => {
        const updatedExpandedItems = { ...prevExpandedItems };
        if (updatedExpandedItems[id]) {
            delete updatedExpandedItems[id];
        } else {
            updatedExpandedItems[id] = true;
        }
        return updatedExpandedItems;
    });
};

export const expandAllItems = (items, itemKey, setExpandedItems, expandedAll, setExpandedAll) => {
    const allExpanded = items.reduce((acc, item) => {
        acc[item[itemKey]] = true;
        return acc;
    }, {});

    setExpandedItems(allExpanded);
    setExpandedAll(!expandedAll);
};


const BuyerInvoicesReport = ({ account, snackbarShowMessage }) => {

    const {orderNumber, transactionNumber} = useUrlQueryParams();

    const [invoices, setInvoices] = useState([]);
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(50);
    const [totalNumberResults, setTotalNumberResults] = useState(0);
    const [selectedLocation, setSelectedLocation] = useState();
    const [startDate, setStartDate] = useState(moment().add(-1, 'months').format(DateFormats.DAY));
    const [endDate, setEndDate] = useState(moment().format(DateFormats.DAY));
    const [bookingNumber, setBookingNumber] = useState(orderNumber);
    const [invoiceNumber, setInvoiceNumber] = useState(transactionNumber);
    const [invoiceType, setInvoiceType] = useState('');
    const [status, setStatus] = useState('');
    const [sortBy, setSortBy] = useState('');
    const [sortDir, setSortDir] = useState('');
    const [expandedItems, setExpandedItems] = useState({});
    const [expandedAll, setExpandedAll] = useState(false);
    const [query, setQuery] = useState(null);

    const handlePageChange = (_, selectedPage) => {
        setPage(selectedPage)
    };

    const handleSizeChange = (event) => {
        setSize(event.target.value)
    };

    const handleToggle = (id) => toggleItem(id, setExpandedItems);
    const handleExpandAll = () => expandAllItems(invoices, 'id', setExpandedItems, expandedAll, setExpandedAll);

    useEffect(() => {
        const { id } = account;
        const checkLength = (number) => number?.length >= 3 ? number : "";

        if (id) {
            setLoading(true)
            const requestParams = {
                buyerAccountId: id,
                page,
                size,
                startDate: startDate,
                endDate: endDate,
                sortBy,
                locationId: selectedLocation?.id,
                bookingNumber: checkLength(bookingNumber),
                invoiceNumber: checkLength(invoiceNumber),
                transactionType: invoiceType,
                status,
                sortDir
            }
            setQuery(requestParams);

            getBuyerInvoices(requestParams)
                .then(({ body }) => {
                    const { content } = body;
                    const data = content?.map((item) => {

                        const dynamicProperties = {};

                        propertiesToCheck.forEach(property => {
                            dynamicProperties[property] = item[property] ? item[property] : "-";
                        });

                        return {
                            ...item,
                            ...dynamicProperties,
                            buyerAmount: formatCurrencyValue(item.buyerAmount),
                            transactionType: BookingTransactionTypeLabel[item?.transactionType] || item?.transactionType,
                            status: getDisplayValue(item?.status),
                            id: item?.transactionId,
                            serviceFeeType: getServiceFeeDisplayValue(item?.serviceFeeType)
                        }
                    });
                    setTotalNumberResults(body.count);
                    setInvoices(data);
                    setLoading(false)
                    setExpandedItems({})
                })
                .catch(err => {
                    setLoading(false)
                    snackbarShowMessage(getErrorMessageForStandardResponse(err), "error", 15000)
                });
        }

    }, [account, page, size, startDate, endDate, selectedLocation, bookingNumber, invoiceNumber, invoiceType, status, sortBy, sortDir]);

    return (
        <Grid item container flexDirection={'column'}>
            <Typography variant='h5' component='h1' mb={4}>Invoices</Typography>
            <BuyerInvoicesFilter
                buyerAccountId={account.id}
                setSelectedLocation={setSelectedLocation}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setBookingNumber={setBookingNumber}
                setInvoiceNumber={setInvoiceNumber}
                setSortBy={setSortBy}
                setSortDir={setSortDir}
                setInvoiceType={setInvoiceType}
                setStatus={setStatus}
                orderNumber={orderNumber}
                transactionNumber={transactionNumber}
            />
            <BuyerInvoicesGroupSummary query={query} />
            {
                invoices.length > 0 &&
                <BuyerInvoicesReportDataSummary
                  accountId={account?.id}
                  query={{
                      startDate: formatDateMMDDyyyy(startDate),
                      endDate: formatDateMMDDyyyy(endDate),
                      sortBy,
                      locationId: selectedLocation?.id,
                      bookingNumber,
                      invoiceNumber,
                      transactionType: invoiceType,
                      status,
                      sortDir
                  }}
                  title={selectedLocation?.locationName || 'All'}
                  count={invoices?.length}
                  setExpandedItems={setExpandedItems}
                  setExpandedAll={setExpandedAll}
                  isShowCollapseButton={true}
                  handleExpandAll={handleExpandAll}
                  expandedItems={expandedItems}
                />
            }
            {
                loading ? [...Array(10).keys()].map((_, index) => (
                    <InfoCardLoader key={index} />
                )) : <BuyerInvoicesTable
                    loading={loading}
                    invoices={invoices}
                    locationId={selectedLocation?.id || ''}
                    invoiceNumber={invoiceNumber}
                    bookingNumber={bookingNumber}
                    invoiceType={invoiceType}
                    sortBy={sortBy}
                    handleToggle={handleToggle}
                    expandedItems={expandedItems}
                />
            }

            {
                invoices.length > 0 && <Grid item>
                    <Pagination
                        count={totalNumberResults}
                        rowsPerPage={size}
                        page={page}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleSizeChange}
                        variant="tablePagination"
                    />
                </Grid>
            }
        </Grid>
    )
}

export default withSnackbar(BuyerInvoicesReport)
