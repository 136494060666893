import React from 'react';
import {FormControl, Grid, Input, InputLabel} from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";

const UploadLegalAgreement = (props: { classes: string | CSSStyleSheet, handleFileUpload: () => void, error: boolean, helperText: string }) => {
  const {classes, handleFileUpload, error, helperText} = props;

  return (
    <Grid item className={classes}>
      <FormControl variant="standard" className={".ss-byb-details-input"}>
        <InputLabel id='ssLegalAgreement' variant={'standard'} color={'primary'} shrink={true}>Legal Agreement</InputLabel>
        <Input
          className='ss-byb-details-input'
          type={'file'}
          onChange={handleFileUpload}
          error={error}
        />
        <FormHelperText error={error}>{helperText}</FormHelperText>
      </FormControl>
    </Grid>
  )
}

export default UploadLegalAgreement;
