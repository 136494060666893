import React from 'react';
import {Checkbox, FormControlLabel, Typography, Link, Grid} from "@mui/material";
import {AccountType} from "./constants/securspace-constants";
import {Link as RouterLink} from "react-router-dom";
import {Theme} from "@mui/material";
import { makeStyles } from '@mui/styles';
import { isBuyerAccountType } from "../util/AccountUtils";

const useStyles: (theme: Theme) => {
  agreementLabel: CSSStyleSheet;
  agreementLabelPartner: CSSStyleSheet;
  link: CSSStyleSheet,
  required: CSSStyleSheet,
  externalFormSpacing: CSSStyleSheet,
} = makeStyles(() => ({
  agreementLabel: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '0.92em',
    lineHeight: '138%',
    letterSpacing: '0.01em',
    marginTop: '0.79em',
  },
  agreementLabelPartner: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '0.92em',
    lineHeight: '1.4em',
    letterSpacing: '0.01em',
    marginTop: '0.79em',
  },
  link: {
    fontSize: '0.92em',
    color: 'rgba(63, 81, 181, 1)',
    textDecoration: 'none'
  },
  required: {
    '&.Mui-required': {
      color: 'tomato'
    }
  },
  externalFormSpacing: {
    flexDirection: 'column'
  }
}));

const TermsAndPolicyCheckbox = (props: { checked: boolean, onChange(event: Object): void, accountType?: string, name: string, disabled: boolean, externalForm?: boolean}) => {
  const {checked, onChange, accountType = AccountType.BUYER, name, disabled = false, externalForm=false} = props;

  const classes = useStyles();

  return (
    <Grid container className={externalForm ? classes.externalFormSpacing : ""}>
        <Grid item>
          <Typography component='p' className={isBuyerAccountType(accountType) ? classes.agreementLabel : classes.agreementLabelPartner} color='textPrimary'>
            I agree to the SecurSpace <RouterLink to={'/terms-of-use'} target={'_blank'} className={classes.link}>Terms Of Use</RouterLink> and <RouterLink to={'/privacy-policy'} target={'_blank'} className={classes.link}>Privacy Policy</RouterLink>, as well as our supplier <Link href={'https://www.dwolla.com/legal/tos/'} target={'_blank'} rel="noopener noreferrer" className={classes.link}>Dwolla's Terms of Service</Link> and <Link href={'https://www.dwolla.com/legal/privacy/'} target={'_blank'} rel="noopener noreferrer" className={classes.link}>Privacy Policy</Link>
          </Typography>
        </Grid>
        <Grid item>
          <FormControlLabel
            className={classes.required}
            label={<Typography variant={'subtitle1'} className={classes.agreementLabel} color='textPrimary' component='span'>Agree to Terms</Typography>}
            control={<Checkbox checked={checked} onChange={onChange} name={name} disabled={disabled} required/>}
            />
        </Grid>
    </Grid>
  );
};

export default TermsAndPolicyCheckbox;