import {
  BookingStatus,
  BookingTransactionType, BookingTransactionTypeLabel,
} from "../../../../constants/securspace-constants";
import {PaymentProcessor, SupplierInvoiceStatusGroup} from "../../../../constants/booking-transaction-constants";

const sortByOptions: {label: string, value: string}[] = [
  {label: 'Invoice Number', value: 'transactionNumber'},
  {label: 'Start Date', value: 'startDate'},
  {label: 'End Date', value: 'endDate'},
  {label: 'Booking Number', value: 'bookingNumber'},
  {label: 'Equipment Type', value: 'assetType'},
  {label: 'Buyer', value: 'buyerCompanyName'},
  {label: 'Supplier', value: 'supplierCompanyName'},
  {label: 'Booking Charge Type', value: 'type'},
  {label: 'Booking Status', value: 'bookingStatus'},
  {label: 'Transaction Status', value: 'supplierTransactionStatus'},
  {label: 'Tax Status', value: 'taxStatus'},
  {label: 'Payment Processor', value: 'paymentProcessor'},
];

const filterByOptions: {name: string, label: string, value: string, numberField?: Boolean}[] = [
  {name: 'bookingNumber', label: 'Booking Number', value: '', numberField: true},
  {name: 'transactionNumber', label: 'Invoice Number', value: '', numberField: true},
  {name: 'buyerCompanyName', label: 'Buyer', value: ''},
  {name: 'supplierCompanyName', label: 'Supplier', value: ''},
];

const filterBySelectOptions: {label: string, value: string, name: string, option: {label: string, value: string}[]}[] = [
  {
    label: 'Booking Charge Type', value: '', name: 'transactionType',
    option: [
      {label: 'All', value: ''},
      ...Object.keys(BookingTransactionType).map(key => ({label: BookingTransactionTypeLabel[key], value: key}))
    ]
  },
  {
    label: 'Booking Status', value: '', name: 'bookingStatus',
    option: [
      {label: 'All', value: ''},
      ...Object.keys(BookingStatus).map(key => ({label: BookingStatus[key], value: key})),
    ]
  },
  {
    label: 'Transaction Status', value: '', name: 'supplierTransactionStatus',
    option: [
      {label: 'All', value: ''},
      ...Object.values(SupplierInvoiceStatusGroup),
    ],
  },
  {
    label: 'Payment Processor', value: '', name: 'paymentProcessor',
    option: [
      {label: 'All', value: ''},
      {label: PaymentProcessor.STRIPE.label, value: PaymentProcessor.STRIPE.value},
      {label: PaymentProcessor.DWOLLA.label, value: PaymentProcessor.DWOLLA.value},
    ]
  }
];

export {
  sortByOptions,
  filterByOptions,
  filterBySelectOptions
};